import React, {useState, useEffect} from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Instagram from '../assets/images/icons-insta-white.svg';
import Facebook from '../assets/images/facebook-logo-white.svg';
import linkedin from '../assets/images/icons-linkedin-white.svg';
import SimpleBar from 'simplebar-react';
import StoresLocation from '../components/StoresLocation';
import parse from "html-react-parser";

import {Link, Trans, useI18next, useTranslation} from 'gatsby-plugin-react-i18next';
import {graphql} from "gatsby";
import Image from "gatsby-image";

const ContactPage: React.FC = ({ location,data: {
    allWpLocation,
    allWpPage
}}) => {

    useEffect(() => {

        //  GTAG CONTACT
        // window.gtag('event', 'conversion', {
        //     'send_to': 'AW-340585242/42ePCNnHnf0CEJrWs6IB'
        // });

    }, []);

    const {t} = useTranslation();
    const [pageLoaded, setPageLoaded] = useState(true);
    const {language, changeLanguage} = useI18next();

    let pageDetailsFull;

    let businessSupport;

    let eventSupport;

    let eshopSupport;

    //  Check language
    if (language !== 'en') {

        pageDetailsFull = allWpPage?.nodes[0]?.pageDetails;

        businessSupport = allWpPage?.nodes[0]?.businessSupport;

        eventSupport = allWpPage?.nodes[0]?.eventSupport;

        eshopSupport = allWpPage?.nodes[0]?.eshopSupport;

    }else{

        pageDetailsFull = allWpPage?.nodes[0]?.translations[0]?.pageDetails;

        businessSupport = allWpPage?.nodes[0]?.translations[0]?.businessSupport;

        eventSupport = allWpPage?.nodes[0]?.translations[0]?.eventSupport;

        eshopSupport = allWpPage?.nodes[0]?.translations[0]?.eshopSupport;

    }

    return (
        <Layout>

            <SEO title="Contact" />
            <div className="body-wrap contact-page">

                <section className="content mainHeader">

                    <div className="container">

                        <div className="row">

                            <h1>{pageDetailsFull?.title}</h1>

                        </div>

                    </div>

                </section>

                {/* STORES */}
                <StoresLocation location={location} locations={allWpLocation} language={language} />

                {/*BUSINESS SUPPORT*/}
                <div className="content-wrapper">

                    <section className="content">

                        <div className="container">

                            <div className="row">

                                <div className="col section-title">

                                    <h2>{businessSupport?.businessMainTitle}</h2>

                                </div>

                            </div>

                            <div className="row">

                                <div className="col-lg-6 col-sm-12">

                                    <Image
                                        fluid={businessSupport?.businessImage?.localFile?.childImageSharp?.fluid}
                                        alt={businessSupport?.businessImage?.localFile?.altText}
                                        className="img-fluid full"
                                    />

                                </div>

                                <div className="col-lg-6 col-sm-12 contact-section contact-area">

                                    <h2 lang={'el'} style={{ textTransform:'uppercase'}}>{ businessSupport?.businessTitle}</h2>

                                    {
                                        (businessSupport?.businessContent) &&
                                        parse(businessSupport?.businessContent)
                                    }

                                </div>

                            </div>

                        </div>

                    </section>

                    {/*EVENTS SUPPORT*/}
                    <section className="content">

                        <div className="container">

                            <div className="row">

                                <div className="col section-title text-end">

                                    <h2>{eventSupport?.eventsMainTitle}</h2>

                                </div>

                            </div>

                            <div className="row">

                                <div className="col-lg-6 col-sm-12 contact-section contact-area">

                                    <h2 lang={'el'} style={{ textTransform:'uppercase'}}>{eventSupport?.eventsTitle}</h2>

                                    {
                                        (eventSupport?.eventsContent) &&
                                        parse( eventSupport?.eventsContent )
                                    }

                                </div>

                                <div className="col-lg-6 col-sm-12">

                                    <Image
                                        fluid={eventSupport?.eventsImage?.localFile?.childImageSharp?.fluid}
                                        alt={businessSupport?.eventsImage?.localFile?.altText}
                                        className="img-fluid full"
                                    />

                                </div>

                            </div>

                        </div>

                    </section>

                    {/*ESHOP SUPPORT*/}
                    <section className="content">

                        <div className="container">

                            <div className="row">

                                <div className="col section-title">

                                    <h2 lang={'el'}>{eshopSupport?.eshopMainTitle}</h2>

                                </div>

                            </div>

                            <div className="row">

                                <div className="col-lg-6 col-sm-12">

                                    <Image
                                        fluid={eshopSupport?.eshopImage?.localFile?.childImageSharp?.fluid}
                                        alt={eshopSupport?.eshopImage?.localFile?.altText}
                                        className="img-fluid full"
                                    />

                                </div>

                                <div className="col-lg-6 col-sm-12 contact-section contact-area">

                                    <h2 lang={'el'} style={{ textTransform:'uppercase'}}>{eshopSupport?.eshopTitle}</h2>

                                    {
                                        (eshopSupport?.eshopContent) &&
                                        parse( eshopSupport?.eshopContent )
                                    }

                                </div>

                            </div>

                        </div>

                    </section>

                </div>

            </div>

        </Layout>
    );
};

export default ContactPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    allWpLocation {
        nodes {
          locationInformation {
            address
            city
            latitude
            longitude
            telephone
            directions
          }
          translations{
            locationInformation {
              address
              city
              latitude
              longitude
              telephone
              directions
            }
          }
        }
      },
      allWpPage(filter: {databaseId: {eq: 616}}) {
    nodes {
      pageDetails {
        title
        titleH3
        titleH3Description
        titleSection
        archiveShowMorePosts
      }
      businessSupport {
        businessContent
        businessDescription
        businessImage {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 580, maxHeight:420, fit: COVER, quality: 80, srcSetBreakpoints: [ 400, 580 ]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
            }
          }
        }
        businessMainTitle
        businessTitle
      }
      eshopSupport {
        eshopContent
        eshopDescription
        eshopMainTitle
        eshopTitle
        eshopImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 580, maxHeight:420, fit: COVER, quality: 80, srcSetBreakpoints: [ 400, 580 ]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
            }
          }
        }
      }
      eventSupport {
        eventsContent
        eventsDescription
        eventsMainTitle
        eventsTitle
        eventsImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 580, maxHeight:420, fit: COVER, quality: 80, srcSetBreakpoints: [ 400, 580 ]) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
            }
          }
        }
      },
      translations{
pageDetails {
        title
        titleH3
        titleH3Description
        titleSection
        archiveShowMorePosts
      }
      businessSupport {
        businessContent
        businessDescription
        businessMainTitle
        businessTitle
      }
      eshopSupport {
        eshopContent
        eshopDescription
        eshopMainTitle
        eshopTitle
      }
      eventSupport {
        eventsContent
        eventsDescription
        eventsMainTitle
        eventsTitle
      }
      }
    }
  }
  }
`;